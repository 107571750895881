import { Directive, Optional, SkipSelf } from '@angular/core';

@Directive({
  selector: '[piniCheckOpen]',
  exportAs: 'checkOpen',
  standalone: true,
})
export class CheckOpenDirective {
  isOpen = false;

  constructor(
    @Optional() @SkipSelf() private checkOpenDirective: CheckOpenDirective,
  ) { }

  isActiveChange() {
    if (this.checkOpenDirective) {
      this.checkOpenDirective.isOpen = true;
      this.checkOpenDirective.isActiveChange();
    }
  }
}
