<mat-toolbar>
  <pd-branding />
  <div class="actions">
    <ng-container *ngTemplateOutlet="startFromHereTemplate; context:{$implicit: getCurrencies$ | async}" />
    <button class="pd-icon-button logout" mat-stroked-button color="primary" (click)="logout()">
      <i class="icon-logout"></i>
    </button>
    <button class="pd-icon-button burger" mat-stroked-button color="primary" (click)="menuOpened.emit()">
      <i class="icon-burger"></i>
    </button>
  </div>
</mat-toolbar>

<ng-template #startFromHereTemplate let-response>
  @if(response === null) {
    <span class="skeleton span-skeleton"></span>
  } @else if(response.ok === false) {
    <button class="pd-icon-button" mat-stroked-button color="primary" (click)="refresh()">
      <i class="icon-refresh"></i>
    </button>
  } @else {
    <mat-select placeholder="..." [panelWidth]="75" class="selected-currency" panelClass="selected-currency-custom-select" hideSingleSelectionIndicator="true" [value]="defaultCurrencyId" (valueChange)="setDefaultCurrency($event)">
      @for (currency of response.body.data.exchangeRates; track $index) {
        <mat-option [value]="currency.id">{{ currency.code }}</mat-option>
      }
    </mat-select>
  }
</ng-template>