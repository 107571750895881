import {ChangeDetectionStrategy, Component, inject, Input, Optional, SkipSelf} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FeatureToggleDirective } from '@shared/directives/feature-toggle.directive';
import { CheckOpenDirective } from '@shared/layout/directives/check-open.directive';
import { MenuItem } from '@shared/layout/services/menu-items';
import { AuthStorageService } from 'src/app/pages/auth/services/auth-storage.service';

@Component({
  selector: 'pd-nav',
  imports: [
    MatListModule,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MatExpansionModule,
    CheckOpenDirective,
    FeatureToggleDirective
  ],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent {
  authService = inject(AuthStorageService);
  @Input() menuItems: MenuItem[] = [];

  constructor(
    @Optional() @SkipSelf() private checkOpenDirective: CheckOpenDirective,
    private authStorageService: AuthStorageService
  ) { }

  logout(route: string | null) {
    route === null && this.authStorageService.logout();
  }

  isActiveChange() {
    if (this.checkOpenDirective) {
      this.checkOpenDirective.isOpen = true;
      this.checkOpenDirective.isActiveChange();
    }
  }
}
