import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { UiService } from '@shared/services/ui.service';
import { map } from 'rxjs';
import { AuthApiService } from '../services/auth-api.service';
import { AuthStatus, AuthStorageService } from '../services/auth-storage.service';

export const accessGuard: CanMatchFn = () => {
  const authStorageService = inject(AuthStorageService);
  const authApiService = inject(AuthApiService);
  const uiService = inject(UiService);
  const router = inject(Router);

  if (authStorageService.authenticationStatus === AuthStatus.pending) {
    if (authStorageService.token) {
      return authApiService.getAuthorization().pipe(
        map(response => {
          if (response.ok) {
            authStorageService.authenticationStatus = AuthStatus.passes;
          } else {
            authStorageService.logout();
          }
          uiService.stopLoading();
          return response.ok;
        })
      );
    } else {
      authStorageService.logout();
      uiService.stopLoading();
      return false;
    }
  } else if (authStorageService.authenticationStatus === AuthStatus.passes) {
    return true;
  } else {
    router.navigateByUrl('/auth/login');
    return false;
  }
};