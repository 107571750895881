import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserPermissions } from '../models/models';

const LOCAL_STORAGE_NAME = 'ma';

export enum AuthStatus {
  pending = 1,
  reject = 2,
  passes = 3,
}

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  private _token: string | null = this.getMainToken();

  get token(): string | null {
    return this._token;
  }

  private _authenticationStatus: AuthStatus = AuthStatus.pending;

  get authenticationStatus(): AuthStatus {
    return this._authenticationStatus;
  }
  set authenticationStatus(value: AuthStatus) {
    this._authenticationStatus = value;
  }

  private _isCorporate = false;

  get isCorporate(): boolean {
    return this._isCorporate;
  }
  set isCorporate(v: boolean) {
    this._isCorporate = v;
  }
  constructor(
    private router: Router,
    private matDialog: MatDialog,
  ) { }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getItem(key: string): string | null {
    const data = localStorage.getItem(key) || '';
    return data;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  length(): number {
    return localStorage.length;
  }

  clear() {
    localStorage.clear();
  }

  getMainToken(): string | null {
    const data = localStorage.getItem(LOCAL_STORAGE_NAME) || '';
    return data;
  }

  setMainToken(value: string) {
    localStorage.setItem(LOCAL_STORAGE_NAME, value);
  }

  removeMainToken() {
    localStorage.removeItem(LOCAL_STORAGE_NAME);
  }

  checkIfItsCorporate(permissions: UserPermissions[]) {
    this.isCorporate = permissions.some(permission => permission.name.toLowerCase() === "corporate");
  }

  logout(redirection = true) {
    this._token = null;
    this.authenticationStatus = AuthStatus.reject;
    this.removeMainToken();
    this.matDialog.closeAll();
    if (redirection) {
      this.router.navigateByUrl('/auth/login');
    }
  }

  login(response: false | { accessToken?: string; permissions?: UserPermissions[]; }, redirection = true) {
    if (response && response.accessToken) {
      this._token = response.accessToken;
      this.authenticationStatus = AuthStatus.passes;

      this.checkIfItsCorporate(response.permissions!);

      this.setMainToken(response.accessToken);
      if (redirection) {
        this.router.navigateByUrl('/dashboard');
      }
    } else {
      this.logout();
    }
  }

}