import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, NgZone, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CurrencyWithExchangeRate } from '@shared/layout/models/models';
import { ApiService } from '@shared/services/api.service';
import { UiService } from '@shared/services/ui.service';
import { of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { AuthStorageService } from 'src/app/pages/auth/services/auth-storage.service';
import { BrandingComponent } from '../branding/branding.component';

@Component({
  selector: 'pd-header',
  imports: [
    MatToolbarModule,
    MatButtonModule,
    BrandingComponent,
    MatSelectModule,
    AsyncPipe,
    NgTemplateOutlet
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Output() menuOpened = new EventEmitter<void>();

  refreshInterval: ReturnType<typeof setInterval>;

  defaultCurrencyId: number;

  getCurrencies$ = this.apiService.get<{ data: CurrencyWithExchangeRate; }>('/user-settings/default-currency-with-exchange-rate').pipe(
    tap(response => {
      if (response.ok) {
        this.defaultCurrencyId = response.body.data.defaultCurrencyId;
        this.setRefreshInterval(response.body.data.refreshIntervalInSecond, response.body.data);
      }
    })
  );

  unsubscribeAll$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private uiService: UiService,
    private authStorageService: AuthStorageService,
    private ngZone: NgZone,
  ) { }

  setRefreshInterval(timerInSecond: number, currencyWithExchangeRate: CurrencyWithExchangeRate): void {
    clearInterval(this.refreshInterval);
    // update the dashboard numbers sequentially
    this.refreshInterval = this.ngZone.runOutsideAngular(() => setInterval(() => {
      this.apiService.get<{ data: CurrencyWithExchangeRate; }>('/user-settings/default-currency-with-exchange-rate').pipe(
        take(1),
        takeUntil(this.unsubscribeAll$)
      ).subscribe(response => this.uiService.defaultCurrencyChanged.next(response.ok ? response.body.data : currencyWithExchangeRate));
    }, timerInSecond * 1000));
  }

  setDefaultCurrency(defaultCurrencyId: number) {
    this.getCurrencies$ = this.apiService.post('/user-settings/set-default-currency', { data: { defaultCurrencyId } }).pipe(
      switchMap(response => {
        if (response.ok) {
          return this.apiService.get<{ data: CurrencyWithExchangeRate; }>('/user-settings/default-currency-with-exchange-rate');
        }

        return of(response);
      })
    ).pipe(
      tap(response => {
        if (response.ok) {
          this.defaultCurrencyId = response.body.data.defaultCurrencyId;

          // update the dashboard numbers once the default currency has been changed
          this.uiService.defaultCurrencyChanged.next(response.body.data);
          this.setRefreshInterval(response.body.data.refreshIntervalInSecond, response.body.data);
        }
      })
    );
  }

  refresh() {
    this.getCurrencies$ = this.apiService.get<{ data: CurrencyWithExchangeRate; }>('/user-settings/default-currency-with-exchange-rate').pipe(
      tap(response => {
        if (response.ok) {
          this.defaultCurrencyId = response.body.data.defaultCurrencyId;
          this.setRefreshInterval(response.body.data.refreshIntervalInSecond, response.body.data);
        }
      })
    );
  }

  logout() {
    this.authStorageService.logout();
  }

  unsubscribeAll(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
    clearInterval(this.refreshInterval);
  }
}