import { Component, EventEmitter, Output } from '@angular/core';
import { ALL_ITEMS } from '@shared/layout/services/menu-items';
import { BrandingComponent } from '../branding/branding.component';
import { NavComponent } from './nav/nav.component';

@Component({
  selector: 'pd-menu',
  imports: [
    NavComponent,
    BrandingComponent
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  @Output() menuClosed = new EventEmitter<void>();

  menuItems = ALL_ITEMS;
}
