import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IError } from '@shared/model/api-models';
import { AlertService } from '@shared/services/alert.service';
import { AuthStorageService } from 'src/app/pages/auth/services/auth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(
    private alertService: AlertService,
    private authStorageService: AuthStorageService,
  ) { }

  handleError(error: HttpErrorResponse): void {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      // client error
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // server error
      let serverErrorMessages = (error.error as IError).messages;

      if (serverErrorMessages === undefined || serverErrorMessages.length === 0) {
        serverErrorMessages = [{ description: error.message }];
      }

      serverErrorMessages.forEach(({ description }) => {
        switch (error.status) {
          case 400:
            // bad request
            errorMessage = `${description}`;
            break;

          case 401:
            errorMessage = `Unauthorized: ${description}`;
            this.authStorageService.logout();
            break;

          case 403:
            errorMessage = `Forbidden: ${description}`;
            break;

          case 404:
            errorMessage = `Not Found: ${description}`;
            break;

          case 500:
            errorMessage = `Internal Server Error: ${description}`;
            break;

          default:
            errorMessage = `Unexpected Error: ${description}`;
            break;
        }

        this.alertService.serverErrorAlert(errorMessage);
      });
    }
  }
}
