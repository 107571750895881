import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

interface IApiResponseOk<E> {
  ok: true;
  body: E;
  error?: never;
  status: number;
}

interface IApiResponseError {
  ok: false;
  error: {
    error: {
      details: { message: string; }[];
    };
  };
  body?: never;
  status: number;
}

export type IResponse<E> = IApiResponseOk<E> | IApiResponseError;

export type TAppendedQuery = { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; };

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = environment.apiEndpoint;

  constructor(
    private http: HttpClient
  ) { }

  get<E = any>(url: string, query?: TAppendedQuery, headers?: { [header: string]: string | string[]; }, usingMockData = false): Observable<IResponse<E>> {
    if (!usingMockData) {
      url = this.baseUrl + url;
    }

    const options: { observe: 'response'; params?: HttpParams; headers?: { [header: string]: string | string[]; }; } = { observe: 'response' };

    if (query) {
      options.params = new HttpParams().appendAll(query);
    }
    if (headers) {
      options.headers = headers;
    }

    return this.http.get<E>(url, options).pipe(
      catchError(response => of(response)),
    );
  }

  post<E = any>(url: string, body: any | null = null): Observable<IResponse<E>> {
    url = this.baseUrl + url;

    return this.http.post<E>(url, body, { observe: 'response' }).pipe(
      catchError(response => of(response)),
    );
  }
}
