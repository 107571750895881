<mat-accordion>
    @for (item of menuItems; track $index) {
        <!-- Check if the item has children -->
        @if (item.children?.length) {
            <mat-expansion-panel class="mat-elevation-z" piniCheckOpen #checkOpen="checkOpen" [expanded]="checkOpen.isOpen">
                <mat-expansion-panel-header>
                    @if (item.icon) {
                        <i [class]="'icon-size-20 icon-' + item.icon"></i>
                    }
                    {{item.name}}
                </mat-expansion-panel-header>

                <!-- Recursive call to render child menu items -->
                <pd-nav [menuItems]="item.children || []" />
            </mat-expansion-panel>
        } @else {
            <!-- Single item without children -->
            @if (item.featureFlagName === 'identityVerification') {
              @if (!authService.isCorporate) {
                <a class="menu-link" *feature="item.featureFlagName" [routerLink]="item.route" (click)="logout(item.route)" routerLinkActive="active" (isActiveChange)="isActiveChange()" [routerLinkActiveOptions]="{ matrixParams:'exact', queryParams: 'exact', paths: 'subset', fragment: 'exact' }">
                  @if (item.icon) {
                    <i [class]="'icon-size-20 icon-' + item.icon"></i>
                  }
                  {{item.name}}
                </a>
              }
            } @else {
              <a class="menu-link" *feature="item.featureFlagName" [routerLink]="item.route" (click)="logout(item.route)" routerLinkActive="active" (isActiveChange)="isActiveChange()" [routerLinkActiveOptions]="{ matrixParams:'exact', queryParams: 'exact', paths: 'subset', fragment: 'exact' }">
                @if (item.icon) {
                  <i [class]="'icon-size-20 icon-' + item.icon"></i>
                }
                {{item.name}}
              </a>
            }
        }
    }
</mat-accordion>
